<template>
  <div>
    <b-modal no-close-on-backdrop :id="id" size="lg" :title="`${this.$route.params.templateId ? 'View/Edit' : 'Create'} Checklist Template`" @hidden="closeModal">
      <b-row>
        <b-col cols="12" v-if="!loading && error">
          <b-alert variant="danger">{{ error }}</b-alert>
        </b-col>
        <b-col class="py-4 text-center" cols="12" v-if="loading">
          <b-spinner variant="primary"></b-spinner>
        </b-col>
      </b-row>
      <b-row v-if="!loading && !error">
        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Name" :invalid-feedback="getValidationMessage('name')" :state="!validationErrors.name">
                <b-form-input type="text" v-model="editedChecklist.name" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <draggable
                v-model="editedChecklist.tasks"
                @start="dragging = true"
                @end="dragging = false"
                handle=".handle"
              >
                <b-card class="mb-2" v-for="task in editedChecklist.tasks" :key="task.id">
                  <b-row>
                    <fa-icon class="handle ml-auto mr-3" icon="bars"/>
                  </b-row>
                  <b-row>
                    <b-col cols="10">
                      <b-form-group label="Checklist Item Name">
                        <b-form-input type="text" v-model="task.name"/>
                      </b-form-group>
                    </b-col>
                    <b-col class="d-flex flex-column justify-content-end" cols="2">
                      <b-button block class="mb-3" @click.prevent="removeChecklistItem(task)" variant="danger"><fa-icon icon="trash" /></b-button>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <b-checkbox class="align-self-center mb-3" v-model="task.is_header">Header</b-checkbox>
                    </b-col>
                  </b-row>
                  <b-row v-if="!task.is_header">
                    <b-col cols="12">
                      <b-form-group label="Checklist Item Description">
                        <b-form-textarea type="text" v-model="task.description"></b-form-textarea>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </draggable>
              <b-button block @click.prevent="addChecklistItem" variant="primary">Add Task...</b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button @click.prevent="closeModal" variant="light">Close</b-button>
        <b-button @click.prevent="saveTemplate" variant="primary">
          <b-spinner class="mx-4" variant="light" small v-if="saving"/>
          <span v-if="!saving">{{ $route.params.templateId ? 'Update' : 'Create' }}</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import handleErrors from '../../mixins/handleErrors'
import draggable from 'vuedraggable'

export default {
  emits: ['hidden'],
  mixins: [handleErrors],
  components: {draggable},
  data() {
    return {
      validationErrors: {},
      genericError: undefined,
      editedChecklist: {
        name: null,
        tasks: [{
          id: null,
          name: '',
          description: '',
          is_header: false
        }]
      },
      loading: false,
      saving: false,
      error: '',
    };
  },
  props: {
    template: Object,
    id: String,
  },
  async mounted() {
    if (this.$route.params.templateId && this.template) {
      this.loading = true;

      try {
        const template = await this.fetchSingle(this.$route.params.templateId);
        this.editedChecklist = {
          tasks: [],
          ...template
        };

        this.loading = false;
      } catch (err) {
        this.error = "Can not load checklist template. Please try again later.";
        this.loading = false;
      }
    }
  },
  methods: {
    ...mapActions('checklistTemplates', ['fetch', 'fetchSingle', 'updateOrCreate']),
    addChecklistItem() {
      this.editedChecklist.tasks.push({
        id: null,
        name: '',
        description: '',
        is_header: false
      });
    },
    removeChecklistItem(task) {
      this.editedChecklist.tasks.splice(this.editedChecklist.tasks.indexOf(task), 1);
    },
    closeModal() {
      this.editedChecklist = {
        name: null,
        tasks: [{
          id: null,
          name: '',
          description: '',
          is_header: false
        }]
      };
      this.validationErrors = {};
      if (this.$route.name !== this.$route.meta.parentName) {
        this.$router.push({ name: this.$route.meta.parentName });
      }
    },
    saveTemplate() {
      this.saving = true;
      this.updateOrCreate(this.editedChecklist).then(() => {
        this.saving = false;
        this.genericError = undefined;
        this.closeModal();
        return this.fetch();
      }).catch(err => {
        if (err.response && err.response.status === 422) {
          this.saving = false;
          this.handleValidationErrors(err);
        } else {
          this.saving = false;
          this.genericError = 'There was an error saving just now. Please try again later.';
        }
      });
    }
  },
  watch: {
    template(value) {
      this.editedChecklist = {
        tasks: [],
        ...value
      };
    }
  }
}
</script>

<style>
</style>
