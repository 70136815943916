<template>
  <div class="d-flex flex-column overflow-auto vh-100">
    <edit-checklist-template id="edit-checklist-template" :template="selectedChecklistTemplates" />
    <sticky-header :title="$route.meta.title">
      <template #buttons>
        <b-button class="ml-2" variant="primary" :to="{name: 'checklist-templates.create'}"><fa-icon class="mr-2" icon="plus" />Create</b-button>
      </template>
    </sticky-header>
    <div class="flex-fill p-3">
      <div class="d-flex flex-column mt-5 py-3 justify-content-center" v-if="loading">
        <b-spinner class="align-self-center" variant="primary" />
        <p class="align-self-center mt-1 mb-0">Loading...</p>
      </div>
      <b-card class="text-center py-5" v-if="!loading && data.length === 0">
        <p class="h3 font-weight-bold">No checklist templates found.</p>
        <p class="text-muted mb-0">Before you can see any checklist templates here, <router-link :to="{name: 'checklist-templates.create'}">try creating one</router-link>.</p>
      </b-card>
      <b-list-group class="mb-4" v-if="!loading && data.length">
        <b-list-group-item class="d-flex" :key="item.id" v-for="item in data">
          <div class="align-self-center flex-fill justify-content-center">
            <p class="h5">{{ item.name }}</p>
            <p class="mb-0 text-muted">Tasks: {{ item.tasks.filter((item) => !item.is_header).length }}</p>
          </div>
          <div class="align-self-center d-flex flex-md-row flex-column">
            <b-button class="mb-1 mb-md-0 ml-md-1" @click.prevent="edit(item.id)" variant="primary" v-b-popover.top.hover="`Edit`"><fa-icon icon="pen" /></b-button>
            <b-button class="mb-1 mb-md-0 ml-md-1" @click.prevent="trash(item.id)" variant="danger" v-b-popover.top.hover="`Delete`"><fa-icon icon="trash" /></b-button>
          </div>
        </b-list-group-item>
      </b-list-group>
      <b-pagination-nav :link-gen="paginationLinkGen" :number-of-pages="lastPage" use-router v-if="lastPage > 1" />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'
import EditChecklistTemplate from './Modals/EditChecklistTemplate.vue'
import StickyHeader from '../components/StickyHeader.vue'
import { mapActions, mapState } from 'vuex'

export default {
  components: { EditChecklistTemplate, StickyHeader },
  data() {
    return {
      selectedChecklistTemplates: {},
    };
  },
  async mounted() {
    if (this.$route.meta.modalId) {
      this.$bvModal.show(this.$route.meta.modalId)
    }

    await this.fetch(this.$route.query.page);
  },
  computed: {
    ...mapState('checklistTemplates', ['data', 'loading', 'lastPage']),
  },
  methods: {
    ...mapActions('checklistTemplates', ['delete', 'fetch']),
    moment,
    edit(id) {
      this.selectedChecklistTemplates = this.data.find(c => c.id === id);

      if (!(this.$route.name === 'checklist-templates.update' && this.$route.params.templateId === id)) {
        this.$router.push({ name: 'checklist-templates.update', params: { templateId: id } });
      }
    },
    paginationLinkGen(pageNo) {
      return pageNo === 1
        ? {name: 'checklist-templates', query: pickBy({...this.$route.params, page: null}, identity)}
        : {name: 'checklist-templates', query: {...this.$route.params, page: pageNo}};
    },
    pluralise(count, term) {
      if (count === 1) return term;
      return `${term}s`;
    },
    trash(id) {
      this.$bvModal.msgBoxConfirm("Are you sure you want to delete this checklist template?", {
        title: 'Delete Checklist Template',
        okText: 'Yes, Delete',
        okVariant: 'danger',
        cancelText: 'No',
        cancelVariant: 'light',
        centered: true
      }).then(shouldDelete => {
        if (!shouldDelete) {
          return;
        }

        return this.delete(id).then(() => this.fetch(this.$route.query.page));
      });
    },
  },
  watch: {
    async '$route'(newValue, value) {
      if (!value.meta.modalId && newValue.meta.modalId) {
        return this.$bvModal.show(newValue.meta.modalId);
      }

      if (newValue.meta.modalId === undefined) {
        this.selectedChecklistTemplates = undefined;
      }

      if (newValue.name === value.name && newValue.query.page !== value.query.page) {
        await this.fetch(this.$route.query.page);
      }

      return this.$bvModal.hide(value.meta.modalId);
    }
  }
}
</script>

<style>

</style>
